import Link from 'next/link';
import { styled } from '@mui/styles';
import finance from 'public/images/icons/job-categories/accounting_finance_and_statistics.svg';
import cs from 'public/images/icons/job-categories/client_customer_service.svg';
import complianceRisk from 'public/images/icons/job-categories/compliance_risk.svg';
import design from 'public/images/icons/job-categories/design_and_creative_arts.svg';
import hr from 'public/images/icons/job-categories/hr_and_recruitment.svg';
import it from 'public/images/icons/job-categories/it_and_technology.svg';
import marketing from 'public/images/icons/job-categories/marketing_and_communications_pr.svg';
import sales from 'public/images/icons/job-categories/sales_and_trade.svg';
import theme from 'theme/theme';

const icons = {
  it: it.src,
  ac: finance.src,
  sa: sales.src,
  ma: marketing.src,
  hr: hr.src,
  de: design.src,
  cs: cs.src,
  co: complianceRisk.src,
};

export const HomeCategoryLink = styled(Link)({
  textDecoration: 'none',
  display: 'block',
});
export const homeCategoryItemStyles = {
  container: {
    width: 'fit-content',
    height: 'auto',
    display: 'flex',
    borderRadius: '71px',
    background: '#FFF',
    boxShadow: '0px 3.345px 3.345px 0px rgba(62, 48, 126, 0.15)',
    padding: '10px 20px',
    alignItems: 'center',
    lineHeight: '100%',
  },
  textWrap: {},
  text: {
    marginLeft: theme.spacing(1.2),
    overflowWrap: 'anywhere',
    color: '#000',
  },
  icon: (icon: string) => ({
    backgroundImage: `url(${icons[icon]})`,
    backgroundRepeat: 'no-repeat',
    width: '28px',
    height: '24px',
    flexShrink: 0,
  }),
};
