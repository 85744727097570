import { Box, Typography } from '@mui/material';
import useTranslate from 'utils/i18n/useTranslate';
import {
  homeBenefitsStyles,
  MoreLink,
} from 'components/home/home-benefits/home-benefits.styles';
import { homeBenefitsCardTwoStyles } from './home-benefits-card-two.styles';

export const HomeBenefitsCardTwo = () => {
  const { t } = useTranslate('home');
  return (
    <Box sx={homeBenefitsCardTwoStyles.benefitCardTwo}>
      <Box sx={homeBenefitsStyles.cardHeader}>
        <Typography variant="h3" sx={homeBenefitsStyles.cardTitle}>
          {t('marketing.match')}
        </Typography>
        <MoreLink href="/post/you-a-dream-job-a-match-made-on-nploy">
          {t('marketing.more')}
        </MoreLink>
      </Box>
      <Box>
        <Typography variant="body2" sx={homeBenefitsStyles.text}>
          When an employer thinks you’re a good fit, they can send you a Match
          Request. All you have to do is swipe to accept, and the process
          begins.
        </Typography>
        <Typography
          component="strong"
          sx={{
            ...homeBenefitsStyles.text,
            ...homeBenefitsStyles.textBold,
          }}
        >
          All jobs in one place..
        </Typography>
        <br />
        <Typography
          component="strong"
          sx={{
            ...homeBenefitsStyles.text,
            ...homeBenefitsStyles.textBold,
          }}
        >
          Bye, endless searching.
        </Typography>
      </Box>
      <Box
        sx={homeBenefitsCardTwoStyles.two}
        aria-label="Graphic showing a push notification from an employer sending a job match request to a candidate on nPloy."
      />
    </Box>
  );
};
