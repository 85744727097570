import { Box, Button, Typography } from '@mui/material';
import useTranslate from 'utils/i18n/useTranslate';
import { LazyLottie } from 'components/lazy-lottie';
import { homeRemoteWorkStyles } from './home-remote-work.styles';

export const HomeRemoteWork = () => {
  const { t } = useTranslate('home');
  return (
    <Box sx={homeRemoteWorkStyles.wrapper}>
      <Typography variant="h3" sx={homeRemoteWorkStyles.title}>
        {t('marketing.workFromAnywhere')}
      </Typography>
      <Box sx={homeRemoteWorkStyles.animationButton}>
        <LazyLottie
          getAnimationData={() => import('./HomePageRemoteChat.json')}
          loop
          id="HomePageRemoteChat"
          alt="Job seeker finding remote work opportunities through nPloy’s extensive job listings."
        />

        <Button sx={homeRemoteWorkStyles.button}>
          {t('marketing.exploreRemoteJobs')}{' '}
          <Typography sx={homeRemoteWorkStyles.buttonIcon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="17"
              viewBox="0 0 19 17"
              fill="none"
            >
              <path
                d="M1.49463 7.76764C0.884806 7.76764 0.390447 8.262 0.390447 8.87183C0.390447 9.48165 0.884806 9.97601 1.49463 9.97601L1.49463 7.76764ZM18.286 9.6526C18.7173 9.22139 18.7173 8.52226 18.286 8.09105L11.2591 1.06408C10.8279 0.632871 10.1287 0.632871 9.69752 1.06408C9.26631 1.49529 9.26631 2.19442 9.69752 2.62563L15.9437 8.87183L9.69752 15.118C9.26631 15.5492 9.26631 16.2484 9.69752 16.6796C10.1287 17.1108 10.8279 17.1108 11.2591 16.6796L18.286 9.6526ZM1.49463 9.97601L17.5053 9.97601L17.5053 7.76765L1.49463 7.76764L1.49463 9.97601Z"
                fill="#150090"
              />
            </svg>
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
