import { FC } from 'react';
import Image from 'next/image';
import { Box } from '@mui/material';
import accenture from 'public/images/icons/companies/Accenture.svg';
import airalo from 'public/images/icons/companies/Airalo.svg';
import amazon from 'public/images/icons/companies/Amazon.svg';
import bic from 'public/images/icons/companies/BIC.svg';
import hedgeserv from 'public/images/icons/companies/Hedgeserv.svg';
import jaEurope from 'public/images/icons/companies/JA-Europe.svg';
import kpmg from 'public/images/icons/companies/KPMG.svg';
import LDC from 'public/images/icons/companies/LDC.svg';
import LIDL from 'public/images/icons/companies/LIDL.svg';
import OpenAI from 'public/images/icons/companies/OpenAI.svg';
import SAP from 'public/images/icons/companies/SAP.svg';
import siteground from 'public/images/icons/companies/Siteground.svg';
import toyota from 'public/images/icons/companies/Toyota.svg';
import trading212 from 'public/images/icons/companies/Trading212.svg';
import binance from 'public/images/icons/companies/binance.svg';
import concentrix from 'public/images/icons/companies/concentrix.svg';
import deloitte from 'public/images/icons/companies/deloitte.svg';
import forbes from 'public/images/icons/companies/forbes.svg';
import hp from 'public/images/icons/companies/hp.svg';
import kraken from 'public/images/icons/companies/kraken.svg';
import mondelez from 'public/images/icons/companies/mondelez.svg';
import ups from 'public/images/icons/companies/ups.svg';
import { IHomePartner } from './home-partner.interface';

const icons = {
  accenture: accenture.src,
  amazon: amazon.src,
  airalo: airalo.src,
  bic: bic.src,
  binance: binance.src,
  concentrix: concentrix.src,
  deloitte: deloitte.src,
  forbes: forbes.src,
  hedgeserv: hedgeserv.src,
  hp: hp.src,
  kpmg: kpmg.src,
  jaEurope: jaEurope.src,
  kraken: kraken.src,
  ldc: LDC.src,
  lidl: LIDL.src,
  mondelez: mondelez.src,
  openAI: OpenAI.src,
  sap: SAP.src,
  siteGround: siteground.src,
  toyota: toyota.src,
  trading212: trading212.src,
  ups: ups.src,
};

export const HomePartner: FC<IHomePartner> = ({
  iconClass,
  altText,
  height,
  width,
}) => (
  <Box>
    <Image
      src={icons[iconClass]}
      alt={altText}
      width={width}
      height={height}
      loading="lazy"
    />
  </Box>
);
