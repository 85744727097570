import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { homeCategories } from 'constants/home-categories';
import useTranslate from 'utils/i18n/useTranslate';
import { useUser } from 'utils/swr/hooks/useUser';
import { useAppDispatch, useHomePageJobsUrl } from 'hooks/*';
import { openCreateAccountModal } from 'store/reducers/uiReducer';
import {
  homeCategoriesStyles,
  SignUpLink,
  ViewAllJobsLink,
} from 'components/home/home-categories/home-categories.styles';
import { HomeCategoryItem } from 'components/home/home-category-item';

export const HomeCategories: FC = () => {
  const { t } = useTranslate('home');
  const dispatch = useAppDispatch();
  const { jobsUrl } = useHomePageJobsUrl();
  const {
    user: { isAuthed },
  } = useUser();

  const handleOpenCreateAccountModal = (
    event: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    event.preventDefault();
    dispatch(openCreateAccountModal());
  };

  return (
    <Box component="section" sx={homeCategoriesStyles.wrapper}>
      <Box
        data-current="Tab 1"
        data-easing="ease"
        data-duration-in="300"
        data-duration-out="100"
        className="w-tabs"
      >
        <Typography component="h4" sx={homeCategoriesStyles.title}>
          {t('marketing.popularCategories')}
        </Typography>

        <Box sx={homeCategoriesStyles.list}>
          {homeCategories.map((item) => (
            <HomeCategoryItem
              key={item.id}
              title={item.title}
              iconClass={item.icon}
              link={item.link}
            />
          ))}
        </Box>
      </Box>
      <Box sx={homeCategoriesStyles.navigationWrapper}>
        {!isAuthed && (
          <Box>
            <SignUpLink
              href={{
                pathname: '/',
                query: { 'create-account': true },
              }}
              onClick={handleOpenCreateAccountModal}
            >
              {t('marketing.createAccount')}
            </SignUpLink>
          </Box>
        )}
        <Box>
          <ViewAllJobsLink href={jobsUrl}>
            {t('marketing.viewAllJobs')}
          </ViewAllJobsLink>
        </Box>
      </Box>
    </Box>
  );
};
