import NextImage from 'next/image';
import { styled } from '@mui/styles';
import appStore from 'public/images/icons/APPStore.svg';
import googlePlay from 'public/images/icons/GPStore.svg';
import theme from 'theme/theme';

export const Image = styled(NextImage)({
  objectFit: 'contain',
  height: '100%',
  maxHeight: '300px',
  maxWidth: '100%',
  [theme.breakpoints.up('md')]: {
    maxHeight: '400px',
  },
});

export const homeFooterStyles = {
  root: {
    maxWidth: '1332px',
    margin: 'auto',
    padding: '10px',
    paddingBottom: '120px',
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      paddingBottom: '150px',
    },
  },
  contentRoot: {
    margin: 'auto',
    padding: '50px 15px 100px 15px',
    borderRadius: '35px',
    background:
      'radial-gradient(84.3% 79.81% at 43.92% 41.53%, #090437 44.4%, #15008F 56.4%, #A89DE9 78.9%)',
    [theme.breakpoints.up('xs')]: {
      paddingBottom: '130px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '220px',
      background:
        'radial-gradient(279.97% 69.21% at 40.36% 50%, #090437 44.4%, #15008F 56.4%, #A89DE9 78.9%)',
    },
  },
  title: {
    color: '#FFF',
    fontSize: '35px',
    fontWeight: 600,
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '46px',
    },
  },
  subtitle: {
    maxWidth: '572px',
    display: 'inline-block',
    color: '#FFF',
    fontSize: '25px',
    fontWeight: 400,
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '30px',
    },
  },
  getStartedButton: {
    display: 'flex',
    height: '53.25px',
    padding: '20.708px 43.389px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '9.861px',
    borderRadius: '65.083px',
    background: '#FFF',
    color: '#080039',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 700,
    marginBottom: '20px',
  },
  linkAppStore: {
    height: '50px',
    width: '139px',
    display: 'flex',
    borderRadius: '12.627px',
    border: '1.148px solid #FFF',
    padding: '10.144px 13.525px',
    gap: '8.453px',
    marginBottom: '20px',
    backgroundImage: `url(${appStore.src}), linear-gradient(122deg, rgba(52, 116, 206, 0.50) 30.73%, rgba(136, 141, 241, 0.50) 91.4%)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  storesButtons: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      bottom: '-30%',
      right: '10%',
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: '-40%',
      right: '10%',
    },
    [theme.breakpoints.up('xl')]: {
      position: 'absolute',
      right: '15%',
    },
  },
  linkGooglePlay: {
    height: '50px',
    width: '139px',
    display: 'flex',
    borderRadius: '12.627px',
    border: '1.148px solid #FFF',
    background:
      'linear-gradient(122deg, rgba(52, 116, 206, 0.50) 30.73%, rgba(136, 141, 241, 0.50) 91.4%)',
    padding: '8.453px 12.68px',
    gap: '8.453px',
    backgroundImage: `url(${googlePlay.src}), linear-gradient(122deg, rgba(52, 116, 206, 0.50) 30.73%, rgba(136, 141, 241, 0.50) 91.4%)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  ctaPhones: {
    position: 'absolute',
    bottom: '-1%',
    left: 0,
    right: 0,
  },
};
