export const homeTestimonialsSliderStyles = {
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    paddingBottom: '20px',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  list: {
    display: 'flex',
    gap: '10px',
    paddingBottom: '20px',
    WebkitOverflowScrolling: 'touch',
  },
  item: {
    flex: '0 0 auto', // Prevent items from shrinking
    width: 'calc(300px)', // Fixed width for each card
    touchAction: 'pan-y pinch-zoom',
  },
};
