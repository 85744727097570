import { FC } from 'react';
import Image from 'next/image';
import { Box, Typography } from '@mui/material';
import { homeCareerItemStyles } from 'components/home/home-career-item/home-career-item.styles';
import { IHomeCareerItem } from './home-career-item.interface';

export const HomeCareerItem: FC<IHomeCareerItem> = ({
  img,
  authorImg,
  authorPosition,
  authorName,
  title,
  readingTime,
}) => (
  <Box sx={homeCareerItemStyles.root}>
    <Box sx={homeCareerItemStyles.imageRoot}>
      <Image
        height={170}
        width={270}
        alt="ss"
        unoptimized
        src={img}
        style={{
          borderRadius: '22px',
        }}
        loading="lazy"
      />
    </Box>

    <Typography sx={homeCareerItemStyles.title}>{title}</Typography>
    <Box sx={homeCareerItemStyles.bottom}>
      <Box>
        <Image
          height={44}
          width={44}
          unoptimized
          style={{ borderRadius: 9999 }}
          src={authorImg}
          alt=""
          loading="lazy"
        />
      </Box>
      <Box sx={homeCareerItemStyles.bottomText}>
        <Typography sx={homeCareerItemStyles.author}>{authorName}</Typography>
        <Typography sx={homeCareerItemStyles.inText} component="span">
          in
          <Typography sx={homeCareerItemStyles.position} component="span">
            {authorPosition}
          </Typography>
        </Typography>
      </Box>
      <Box>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="23"
          viewBox="0 0 22 23"
          fill="none"
        >
          <g clipPath="url(#clip0_150_139)">
            <mask
              id="mask0_150_139"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="23"
              height="23"
            >
              <path
                d="M11.1049 20.0266C15.9576 20.0266 19.8915 16.0927 19.8915 11.24C19.8915 6.38729 15.9576 2.45341 11.1049 2.45341C6.25225 2.45341 2.31836 6.38729 2.31836 11.24C2.31836 16.0927 6.25225 20.0266 11.1049 20.0266Z"
                stroke="#15E7B5"
                strokeWidth="4.21756"
              />
            </mask>
            <g mask="url(#mask0_150_139)">
              <path
                d="M11.1049 20.0266C15.9576 20.0266 19.8915 16.0927 19.8915 11.24C19.8915 6.38731 15.9576 2.45342 11.1049 2.45342C6.25225 2.45342 2.31836 6.38731 2.31836 11.24C2.31836 16.0927 6.25225 20.0266 11.1049 20.0266Z"
                stroke="#15E7B5"
                strokeWidth="4.21756"
              />
              <mask
                id="mask1_150_139"
                maskUnits="userSpaceOnUse"
                x="-2"
                y="-4"
                width="28"
                height="30"
              >
                <path
                  d="M11.1047 9.48268V-0.00682831L0.209366 -2.11561C-0.493561 3.15634 -1.89941 14.2626 -1.89941 16.5119C-1.89941 18.7613 -0.493561 23.3069 0.209366 25.2985L19.8913 23.8927L25.1633 13.7002L17.4311 -3.17L11.1047 9.48268Z"
                  fill="#D9D9D9"
                />
              </mask>
              <g mask="url(#mask1_150_139)">
                <path
                  d="M11.1049 20.0266C15.9576 20.0266 19.8915 16.0927 19.8915 11.24C19.8915 6.38731 15.9576 2.45342 11.1049 2.45342C6.25225 2.45342 2.31836 6.38731 2.31836 11.24C2.31836 16.0927 6.25225 20.0266 11.1049 20.0266Z"
                  stroke="#D9D9D9"
                  strokeWidth="4.21756"
                />
              </g>
            </g>
          </g>
          <defs>
            <clipPath id="clip0_150_139">
              <rect
                width="21.7907"
                height="22.4937"
                fill="white"
                transform="translate(0.209473 -0.00683594)"
              />
            </clipPath>
          </defs>
        </svg>
        <Typography sx={homeCareerItemStyles.readTimeText}>
          {readingTime}
        </Typography>
      </Box>
    </Box>
  </Box>
);
