export const homeCareerItemStyles = {
  root: {
    padding: '15px',
    width: '300px',
    height: '350px',
    borderRadius: '30px',
    background: '#FFF',
    boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.25)',
    margin: '0 auto',
  },
  imageRoot: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#000',
    marginBottom: '10px',
  },
  title: {
    color: '#000',
    fontSize: '20.64px',
    fontWeight: 700,
    textAlign: 'left',
    marginTop: '10px',
    marginBottom: '30px',
  },
  author: {
    color: '#000',
    fontSize: '14.443px',
    fontWeight: 700,
  },
  bottom: {
    display: 'flex',
    padding: '0',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  bottomText: {
    textAlign: 'left',
  },
  inText: {
    display: 'flex',
    color: '#000',
    fontSize: '14.443px',
    fontWeight: 400,
    gap: '5px',
  },
  position: {
    color: '#15E7B5',
    fontSize: '14.443px',
    fontWeight: 700,
  },
  readTimeText: {
    color: '#000',
    fontSize: '14.443px',
    fontWeight: 400,
  },
};
