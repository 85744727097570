import { FC } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import theme from 'theme/theme';
import useTranslate from 'utils/i18n/useTranslate';
import { HomeCareerSlider } from 'components/home/home-career-slider';
import { homeCareerStyles, LeanMoreLink } from './home-career.styles';

export const HomeCareer: FC = () => {
  const { t } = useTranslate('home');
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Box sx={homeCareerStyles.root}>
      <Box sx={homeCareerStyles.topWrapper}>
        <Typography variant="h3" sx={homeCareerStyles.title}>
          {t('marketing.careerInsights')}
        </Typography>
        {matches && (
          <LeanMoreLink href="/blog/home">
            {t('marketing.learnMore')}
          </LeanMoreLink>
        )}
      </Box>
      <Box sx={homeCareerStyles.list}>
        <HomeCareerSlider />
      </Box>
      {!matches && (
        <LeanMoreLink href="/blog/home">
          {t('marketing.learnMore')}
        </LeanMoreLink>
      )}
    </Box>
  );
};
