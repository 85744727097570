import theme from 'theme/theme';

export const homePartnersStyles = {
  wrap: {
    backgroundColor: '#040028',
    flexFlow: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '45px 15px 55px',
    display: 'flex',
    position: 'relative',
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: 600,
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up('md')]: {
      fontSize: '45px',
    },
  },
  logosWrapper: {
    maxWidth: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  logos: {
    overflowX: 'hidden',
    gridColumnGap: '20px',
    gridRowGap: '16px',
    flexFlow: 'wrap',
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: '1fr 1fr',
    gridAutoColumns: '1fr',
    alignContent: 'flex-start',
    justifyContent: 'center',
    alignItems: 'flex-start',
    display: 'flex',
    maxWidth: '960px',
  },
};
