import { Box, Typography } from '@mui/material';
import useTranslate from 'utils/i18n/useTranslate';
import {
  homeBenefitsStyles,
  MoreLink,
} from 'components/home/home-benefits/home-benefits.styles';
import { homeBenefitsCardOneStyles } from './home-benefits-card-one.styles';

export const HomeBenefitsCardOne = () => {
  const { t } = useTranslate('home');
  return (
    <Box sx={homeBenefitsCardOneStyles.benefitCard}>
      <Box sx={homeBenefitsStyles.cardHeader}>
        <Typography variant="h3" sx={homeBenefitsStyles.cardTitle}>
          {t('marketing.smart')} <br />
          {t('marketing.algorithm')}
        </Typography>
        <MoreLink href="/post/how-does-our-matching-algorithm-work">
          {t('marketing.more')}
        </MoreLink>
      </Box>
      <Box>
        <Typography variant="body2" sx={homeBenefitsStyles.text}>
          An AI algorithm that matches you with employers based on your talent.
          You just have to upload or create your resume, set your desired
          salary, and let our AI do the rest.
        </Typography>
        <Typography
          component="strong"
          sx={{
            ...homeBenefitsStyles.text,
            ...homeBenefitsStyles.textBold,
          }}
        >
          It’s never been easier.
        </Typography>
      </Box>
      <Box
        sx={homeBenefitsCardOneStyles.one}
        aria-label="Illustration of nPloy’s AI algorithm matching candidates with ideal employers"
      />
    </Box>
  );
};
