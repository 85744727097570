import Link from 'next/link';
import { SxProps, Theme } from '@mui/material';
import { styled } from '@mui/styles';
import theme from 'theme/theme';

export const homeCategoriesStyles: Record<string, SxProps<Theme>> = {
  wrapper: {
    padding: `${theme.spacing(3.5)} ${theme.spacing(2)}`,
    backgroundColor: '#F7F7F7',
    maxWidth: '1332px',
    margin: 'auto',
  },
  title: {
    color: theme.palette.common.black,
    fontSize: '35px',
    fontWeight: 'bold',
    lineHeight: '43px',
    alignSelf: 'stretch',
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
    },
  },
  navigationWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    justifyContent: 'center',
    padding: `${theme.spacing(2.5)} 0 `,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'start',
    },
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
};

export const SignUpLink = styled(Link)({
  display: 'block',
  borderRadius: '66px',
  background: '#090437',
  height: '54px',
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  fontSize: '20px',
  fontWeight: 600,
  width: 'fit-content',
  color: '#fff',
  textDecoration: 'none',
  lineHeight: '20px',
  '&:hover': {
    background: '#53FFD6',
  },
});

export const ViewAllJobsLink = styled(Link)({
  display: 'block',
  borderRadius: '66px',
  height: '54px',
  padding: `${theme.spacing(1.8)} ${theme.spacing(4)}`,
  color: '#090437',
  fontSize: '18px',
  fontWeight: 600,
  width: 'fit-content',
  border: '2px solid #090437',
  textDecoration: 'none',
  lineHeight: '20px',
  '&:hover': {
    background: '#53FFD6',
    border: '2px solid #53FFD6',
    color: '#038465',
  },
});
