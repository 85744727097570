import { FC } from 'react';
import Image from 'next/image';
import { Box, Typography } from '@mui/material';
import nployLogo from 'public/images/icons/nPloy_LightLogo_Slogan 1.svg';
import useTranslate from 'utils/i18n/useTranslate';
import { HomeTestimonialsCommentOne } from 'components/home/home-testimonials-comment-one';
import { homeTestimonialsCommentOneStyles } from 'components/home/home-testimonials-comment-one/home-testimonials-comment-one.styles';
import { HomeTestimonialsSlider } from 'components/home/home-testimonials-slider';
import { LazyLottie } from 'components/lazy-lottie';
import { homeTestimonialsStyles } from './home-testimonials.styles';

export const HomeTestimonials: FC = () => {
  const { t } = useTranslate('home');

  return (
    <Box component="section" sx={homeTestimonialsStyles.wrapper}>
      <Box sx={homeTestimonialsStyles.uiWrapper}>
        <Box sx={homeTestimonialsStyles.topWrapper}>
          <>
            <Typography variant="h2" sx={homeTestimonialsStyles.title}>
              {t('marketing.whyCandidates')}
            </Typography>
            <Typography variant="h2" sx={homeTestimonialsStyles.title}>
              <Typography
                component="span"
                sx={homeTestimonialsStyles.titleGradient}
              >
                {t('marketing.love')}
              </Typography>
              <Image
                src={nployLogo}
                alt="nPloy"
                width={53}
                height={49}
                loading="lazy"
              />
              &zwj;
              {t('marketing.us')}
            </Typography>
          </>
          <Typography sx={homeTestimonialsStyles.subtitle}>
            We’re driven to be the top{' '}
            <Typography
              sx={{
                ...homeTestimonialsStyles.subtitle,
                ...homeTestimonialsStyles.subtitleGradient,
              }}
              component="span"
            >
              job app
            </Typography>
            , customized for your career path.
          </Typography>
        </Box>
        <HomeTestimonialsCommentOne
          author="nPloy saved me from my old job."
          text="It was like a matchmaking system. It's pretty cool."
        />
        <HomeTestimonialsCommentOne
          author="Partner Operations"
          text="I just created a profile and the employer found me."
          styles={homeTestimonialsStyles.popupQuotes3}
          ninjaIconStyles={homeTestimonialsStyles.ninjaIcon3}
        />
        <HomeTestimonialsCommentOne
          text={
            <Typography
              component="p"
              sx={homeTestimonialsCommentOneStyles.popupQuotesText}
            >
              <strong>nPloy</strong> saved me from my old job.
            </Typography>
          }
          author="Incident Manaer"
          styles={homeTestimonialsStyles.popupQuotes2}
          ninjaIconStyles={homeTestimonialsStyles.ninjaIcon2}
        />
      </Box>
      <Box sx={homeTestimonialsStyles.sliderWrapper}>
        <Box sx={homeTestimonialsStyles.animation}>
          <LazyLottie
            getAnimationData={() =>
              import(
                'components/home/home-testimonials-slider/ScrollAnimationLight.json'
              )
            }
            loop
            id="ScrollAnimationLight"
          />
        </Box>
        <Box sx={homeTestimonialsStyles.scrollable}>
          <HomeTestimonialsSlider />
        </Box>

        {/* <Link */}
        {/*  href="/about-us/our-mobile-app" */}
        {/*  style={{ */}
        {/*    textDecoration: 'none', */}
        {/*  }} */}
        {/* > */}
        {/*  <Box sx={homeTestimonialsStyles.reviewButton}> */}
        {/*    {t('marketing.reviewNploy')} */}
        {/*  </Box> */}
        {/* </Link> */}
      </Box>
    </Box>
  );
};
