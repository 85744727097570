import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import phone from 'public/images/cta-phones.webp';
import { homeFooterStyles, Image } from './home-footer.styles';

export const HomeFooter: FC = () => (
  <Box sx={homeFooterStyles.root}>
    <Box sx={homeFooterStyles.contentRoot}>
      <Box
        sx={{
          maxWidth: '800px',
          margin: '0 auto',
          position: 'relative',
        }}
      >
        <Typography sx={homeFooterStyles.title}>
          Come and get your dream career
        </Typography>
        <Typography variant="body1" sx={homeFooterStyles.subtitle}>
          Let <strong>nPloy</strong> do the job search for you. We’re not your
          average job app.
        </Typography>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Button sx={homeFooterStyles.getStartedButton}>Get started</Button>
          <Box sx={homeFooterStyles.storesButtons}>
            <Button
              sx={homeFooterStyles.linkAppStore}
              aria-label="Download on the app store"
            />
            <Button
              sx={homeFooterStyles.linkGooglePlay}
              aria-label="Get it on Google play"
            />
          </Box>
        </Box>
      </Box>
    </Box>
    <Box sx={homeFooterStyles.ctaPhones}>
      <Image
        unoptimized
        loading="lazy"
        src={phone}
        alt="Motivated professional ready to start their dream career journey and find the perfect job match with nPloy"
      />
    </Box>
  </Box>
);
