import type { LottieComponentProps } from 'lottie-react';
import { lazy, Suspense } from 'react';
import useSWR from 'swr';
import { Skeleton } from '@mui/material';
import { ILazyLottie } from 'components/lazy-lottie/lazy-lottie.interface';

const LazyLottieComponent = lazy(() => import('./home-hero-lottie.styles'));

export const HomeHeroLottie = <T extends Record<string, unknown>>({
  getAnimationData,
  id,
  ref,
  ...props
}: ILazyLottie<T> & Omit<LottieComponentProps, 'animationData'>) => {
  const { data } = useSWR(
    typeof window !== 'undefined' ? id : null,
    async () => {
      import('lottie-react');
      return getAnimationData();
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  if (!data) return <Skeleton width="100%" height="100%" />;

  return (
    <Suspense fallback={<Skeleton width="100%" height="100%" />}>
      <LazyLottieComponent animationData={data} {...props} />
    </Suspense>
  );
};
