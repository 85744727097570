import { FC } from 'react';
import { Box } from '@mui/material';
import { nployFeedbacks } from 'constants/nploy-feedbacks';
import { HomeTestimonialsComment } from 'components/home/home-testimonials-comment';
import { homeTestimonialsSliderStyles } from 'components/home/home-testimonials-slider/home-testimonials-slider.styles';

export const HomeTestimonialsSlider: FC = () => (
  <Box sx={homeTestimonialsSliderStyles.root}>
    <Box sx={homeTestimonialsSliderStyles.list}>
      {nployFeedbacks.map(({ id, comment, authorName, authorPosition }) => (
        <Box
          sx={homeTestimonialsSliderStyles.item}
          key={`nploy-feedback-${id}`}
        >
          <HomeTestimonialsComment
            comment={comment}
            name={authorName}
            position={authorPosition}
          />
        </Box>
      ))}
    </Box>
  </Box>
);
