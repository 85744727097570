import theme from 'theme/theme';

export const homeRemoteWorkStyles = {
  wrapper: {
    padding: '40px 10px 60px 10px',
    gap: '10px',
    background: '#15E7B5',
  },
  animationButton: {
    maxWidth: '800px',
    margin: 'auto',
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: '46px',
    fontWeight: 600,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '23px',
    fontSize: '20px',
    padding: '16px 20px',
    background: '#040028',
    boxShadow: '0px 2.807px 5.614px 0px rgba(0, 0, 0, 0.15)',
    color: '#FFF',
    fontWeight: 600,
    gap: '10px',
    margin: 'auto',
    '&:hover': {
      colors: '#150090',

      '& p': {
        background: '#150090',
        '& svg>path': {
          fill: '#FFF',
        },
      },
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: '24.824px',
      padding: '16px 25px',
    },
    [theme.breakpoints.up('md')]: {
      margin: 0,
      marginLeft: 'auto',
    },
  },
  buttonIcon: {
    display: 'flex',
    width: '45px',
    height: '45px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '30px',
    background: '#FFF',
  },
};
