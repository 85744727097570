import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import parseHtml from 'html-react-parser';
import { IHomeTestimonialsComment } from './home-testimonials-comment.interface';
import { homeTestimonialsCommentStyles } from './home-testimonials-comment.styles';

export const HomeTestimonialsComment: FC<IHomeTestimonialsComment> = ({
  name,
  comment,
  position,
}) => (
  <Box sx={homeTestimonialsCommentStyles.wrapper}>
    <Typography sx={homeTestimonialsCommentStyles.author}>{name}</Typography>
    <Box sx={homeTestimonialsCommentStyles.position}>{position}</Box>
    <Box className="spacer _15" />
    <Typography sx={homeTestimonialsCommentStyles.comment}>
      {parseHtml(comment)}
    </Typography>
  </Box>
);
