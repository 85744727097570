import Link from 'next/link';
import { styled } from '@mui/styles';
import theme from 'theme/theme';

export const LeanMoreLink = styled(Link)({
  textDecoration: 'none',
  display: 'flex',
  height: '54px',
  maxWidth: 'fit-content',
  padding: '20px 35px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  color: '#FFF',
  textAlign: 'center',
  fontSize: '20px',
  fontWeight: 600,
  borderRadius: '66px',
  background: '#090437',
  '&:hover': {
    background: '#53FFD6',
  },
});

export const homeCareerStyles = {
  root: {
    backgroundColor: 'white',
    padding: '20px 15px',
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  topWrapper: {
    minWidth: '240px',
    height: '100%',
    marginRight: '30px',
  },
  title: {
    color: '#000',
    fontSize: '35px',
    fontWeight: 600,
    textAlign: 'left',
    maxWidth: '230px',
    marginBottom: '50px',
  },
  list: {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  button: {
    display: 'flex',
    height: '54px',
    padding: '20px 35px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    color: '#FFF',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 600,
    borderRadius: '66px',
    background: '#090437',
  },
};
