export const homeCategories = [
  {
    id: 1,
    title: 'IT & Technology',
    icon: 'it',
    jobsCount: 45,
    link: '/jobs?category=IT',
  },
  {
    id: 2,
    title: 'Accounting and Finance',
    icon: 'ac',
    jobsCount: 65,
    link: '/jobs?category=Accounting',
  },
  {
    id: 3,
    title: 'Marketing',
    icon: 'ma',
    jobsCount: 65,
    link: '/jobs?category=Marketing',
  },
  {
    id: 4,
    title: 'Customer Service',
    icon: 'cs',
    jobsCount: 65,
    link: '/jobs?category=Customer',
  },
  {
    id: 5,
    title: 'Compliance and Risk',
    icon: 'co',
    jobsCount: 65,
    link: '/jobs?category=Compliance',
  },
  {
    id: 6,
    title: 'Sales and Trade',
    icon: 'sa',
    jobsCount: 65,
    link: '/jobs?category=Sales',
  },
  {
    id: 7,
    title: 'Human Resources',
    icon: 'hr',
    jobsCount: 65,
    link: '/jobs?category=HR',
  },
  {
    id: 8,
    title: 'Design',
    icon: 'de',
    jobsCount: 65,
    link: '/jobs?category=Design',
  },
];

export const nployPartners = [
  {
    id: 'binance',
    name: 'Binance',
    logo: 'binance',
    alt: 'Binance careers – explore remote job opportunities in Web3',
    width: 39,
    height: 20,
  },
  {
    id: 'hp',
    name: 'HP',
    logo: 'hp',
    alt: 'HP careers – explore job opportunities with a featured employer on nPloy',
    width: 25,
    height: 24,
  },
  {
    id: 'accenture',
    name: 'Accenture',
    logo: 'accenture',
    alt: 'Accenture careers – explore remote and on-site it job opportunities on nPloy',
    width: 48,
    height: 21,
  },
  {
    id: 'ldc',
    name: 'LDC',
    logo: 'ldc',
    alt: 'LDC careers – explore agriculture job opportunities on nPloy',
    width: 37,
    height: 18,
  },
  {
    id: 'sap',
    name: 'SAP',
    logo: 'sap',
    alt: 'SAP careers – explore it job opportunities with featured employers on nPloy',
    width: 30,
    height: 21,
  },
  {
    id: 'amazon',
    name: 'Amazon',
    logo: 'amazon',
    alt: 'Amazon careers – explore remote and on-site job opportunities on nPloy',
    width: 47,
    height: 15,
  },
  {
    id: 'concentrix',
    name: 'Concentrix',
    logo: 'concentrix',
    alt: 'Concentrix careers – explore customer service job opportunities on nPloy',
    width: 64,
    height: 10,
  },
  {
    id: 'lidl',
    name: 'LIDL',
    logo: 'lidl',
    alt: 'Lidl careers – explore retail job opportunities on nPloy',
    width: 30,
    height: 30,
  },
  {
    id: 'forbes',
    name: 'FORBES',
    logo: 'forbes',
    alt: 'Forbes careers – explore job opportunities on nPloy',
    width: 46,
    height: 15,
  },
  {
    id: 'jaEurope',
    name: 'Ja Europe',
    logo: 'jaEurope',
    alt: 'JA careers – explore entry level job opportunities on nPloy',
    width: 53,
    height: 24,
  },
  {
    id: 'bic',
    name: 'BIC',
    logo: 'bic',
    alt: 'Bic careers – explore job opportunities with featured employers on nPloy',
    width: 46,
    height: 14,
  },
  {
    id: 'kpmg',
    name: 'KPMG',
    logo: 'kpmg',
    alt: 'KPMG careers – explore finance and accounting job opportunities on nPloy',
    width: 47,
    height: 22,
  },
  {
    id: 'deloitte',
    name: 'Deloitte',
    logo: 'deloitte',
    alt: 'Deloitte careers - explore job opportunities in consulting and finance on nPloy',
    width: 57,
    height: 11,
  },
  {
    id: 'siteGround',
    name: 'Site Ground',
    logo: 'siteGround',
    alt: 'SiteGround careers – explore tech job opportunities on nPloy',
    width: 61,
    height: 13,
  },
  {
    id: 'toyota',
    name: 'Toyota',
    logo: 'toyota',
    alt: 'Toyota jobs - explore careers in the automative industry on nPloy',
    width: 33,
    height: 24,
  },
  {
    id: 'kraken',
    name: 'Kraken',
    logo: 'kraken',
    alt: 'Kraken careers – explore remote job opportunities in Web3',
    width: 62,
    height: 11,
  },
  {
    id: 'openAI',
    name: 'Open AI',
    logo: 'openAI',
    alt: 'OpenAI careers – explore remote job opportunities',
    width: 58,
    height: 17,
  },
  {
    id: 'mondelez',
    name: 'Mondelez',
    logo: 'mondelez',
    alt: 'Mondelez careers – explore FMCG job opportunities on nPloy',
    width: 59,
    height: 14,
  },
  {
    id: 'ups',
    name: 'UPS',
    logo: 'ups',
    alt: 'UPS jobs - explore supply chain careers on nPloy',
    width: 20,
    height: 24,
  },
  {
    id: 'trading212',
    name: 'Trading 212',
    logo: 'trading212',
    alt: 'Trading 212 careers – explore fintech job opportunities on nPloy',
    width: 61,
    height: 15,
  },
  {
    id: 'hedgeserv',
    name: 'Hedgeserv',
    logo: 'hedgeserv',
    alt: 'Hedgeserv careers – explore job opportunities on nPloy',
    width: 73,
    height: 15,
  },
];
