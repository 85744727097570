import styled from '@emotion/styled';
import Link from 'next/link';
import phone from 'public/images/phone-transition.webp';
import theme from 'theme/theme';

export const AppLink = styled(Link)({
  display: 'flex',
  height: '54px',
  padding: '20px 35px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '66px',
  background: '#090437',
  color: '#FFF',
  fontSize: '20px',
  fontWeight: 600,
  textDecoration: 'none',
});

export const MoreLink = styled(Link)({
  display: 'flex',
  padding: '5px 15px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '70px',
  background: '#000',
  color: '#fff',
  textDecoration: 'none',
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: '#15E7B5',
  },
});

export const homeBenefitsStyles = {
  wrap: {
    padding: '0 10px',
    position: 'relative',
    gridColumnGap: '20px',
    gridRowGap: '20px',
    backgroundColor: '#fff',
    flexFlow: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '70px',
    paddingBottom: '40px',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '90px',
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '120px',
    },
  },
  phone: {
    zIndex: 1,
    backgroundImage: `url("${phone.src}")`,
    backgroundPosition: '50% -15px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '260px',
    position: 'absolute',
    left: '0%',
    right: '0%',
    top: '-1%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
      margin: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      top: '-2%',
    },
    [theme.breakpoints.up('md')]: {
      top: '-5%',
    },
  },
  subtitle: {
    maxWidth: '800px',
    color: '#262626',
    textAlign: 'center',
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 400,
  },
  cardWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gridColumnGap: '20px',
    gridRowGap: '20px',
    padding: '20px 10px',
    alignItems: 'center',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    zIndex: 10,
  },
  cardTitle: {
    color: '#000',
    fontSize: '40px !Important',
    fontWeight: '600',
  },
  text: {
    color: '#000',
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: 400,
  },
  textBold: {
    fontWeight: 700,
  },
};
