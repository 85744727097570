import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { nployPartners } from 'constants/home-categories';
import useTranslate from 'utils/i18n/useTranslate';
import { HomePartner } from 'components/home/home-partner';
import { homePartnersStyles } from 'components/home/home-partners/home-partners.styles';

export const HomePartners: FC = () => {
  const { t } = useTranslate('home');
  return (
    <Box component="section" sx={homePartnersStyles.wrap}>
      <Typography sx={homePartnersStyles.title}>
        {t('marketing.employersList')}
      </Typography>
      <Box sx={homePartnersStyles.logos}>
        <Box sx={homePartnersStyles.logos}>
          {nployPartners.map(({ id, logo, alt, width, height }) => (
            <HomePartner
              iconClass={logo}
              key={id}
              altText={alt}
              width={width}
              height={height}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
