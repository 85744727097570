import { FC } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import theme from 'theme/theme';
import { HomeCareerItem } from 'components/home/home-career-item';
import { homeCareerSliderStyles } from './home-career-slider.styles';

export const HomeCareerSlider: FC = () => {
  const xl = useMediaQuery(theme.breakpoints.up('xl'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <Box sx={homeCareerSliderStyles.root}>
      <Box sx={homeCareerSliderStyles.list}>
        <Box sx={homeCareerSliderStyles.item}>
          <HomeCareerItem
            title="Let’s talk money & incentives"
            authorName="Iveta Stoyanova"
            authorPosition="Recruit Smarter"
            img="https://placehold.co/270x170"
            authorImg="https://placehold.co/43x43"
            readingTime="4h"
          />
        </Box>
        {xl && (
          <Box sx={homeCareerSliderStyles.item}>
            <HomeCareerItem
              title="Let’s talk money & incentives"
              authorName="Iveta Stoyanova"
              authorPosition="Recruit Smarter"
              img="https://placehold.co/270x170"
              authorImg="https://placehold.co/43x43"
              readingTime="4h"
            />
          </Box>
        )}

        {md && (
          <Box sx={homeCareerSliderStyles.item}>
            <HomeCareerItem
              title="Let’s talk money & incentives"
              authorName="Iveta Stoyanova"
              authorPosition="Recruit Smarter"
              img="https://placehold.co/270x170"
              authorImg="https://placehold.co/43x43"
              readingTime="4h"
            />
          </Box>
        )}
        {lg && (
          <Box sx={homeCareerSliderStyles.item}>
            <HomeCareerItem
              title="Let’s talk money & incentives"
              authorName="Iveta Stoyanova"
              authorPosition="Recruit Smarter"
              img="https://placehold.co/270x170"
              authorImg="https://placehold.co/43x43"
              readingTime="4h"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
