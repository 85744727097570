export const homeTestimonialsCommentStyles = {
  wrapper: {
    flex: 'none',
    width: '300px',
    padding: '23px 27px',
    gap: '6px',
    borderRadius: '26px',
    border: '2px solid #15E7B5',
    background: '#FFF',
  },
  author: {
    color: '#0E0E0E',
    fontSize: '12px',
    fontWeight: 700,
  },
  position: {
    color: '#0E0E0E',
    fontSize: '12px',
    fontWeight: 300,
  },
  comment: {
    color: '#150090',
    fontSize: '22px',
    fontWeight: 600,
  },
};
